import React from "react"

import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomepageCollage from "../components/gtri-historical-archive-collage"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Row>
      <Col id="page-title" className="col col-12">
        <h1 className="title">GTRI Historical Archive</h1>
      </Col>
    </Row>
    <Row id="content">
      <Col id="block-system-main">
        <Row className="justify-content-center p-3">
          <HomepageCollage />
        </Row>
        <Row>
          <p className="intro-text">
            Problem. Solved. The pioneering Georgia legislators and regents who
            founded the State Engineering Experiment Station — now known as the{" "}
            <a href="https://gtri.gatech.edu">
              Georgia Tech Research Institute
            </a>{" "}
            (GTRI) — would no doubt be impressed and amazed with what they
            started in 1934. While they might not recognize today's GTRI, the
            organization's fundamental purpose still rings familiar — real-world
            research that solves tough problems for government and industry. We
            welcome you to our online historical archive.
          </p>
        </Row>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
